import * as api from '@/requests/api/post';
import { usePromptStore } from '@/store';
import type { BaseInstance } from '@pigello/pigello-matrix';
import type { RequestData } from '../types';
import { patch } from './patch';

type Next = () => Promise<{
  data: BaseInstance | Array<BaseInstance> | Record<string, unknown>;
}>;

const isObject = (obj: unknown): obj is Record<string, unknown> => {
  return typeof obj === 'object' && obj !== null;
};

const checkParsedError = (err: unknown) => {
  if (!isObject(err)) {
    return false;
  }

  if (!err) {
    return false;
  }

  if ('mt_motivation' in err) {
    return true;
  }

  if (
    '__all__' in err &&
    typeof err['__all__'] === 'string' &&
    err['__all__']?.includes('(1013)')
  ) {
    return true;
  }

  return false;
};

export const handleMotivation = async (
  res: Response,
  requestData?: RequestData
): Promise<{
  next: Next | undefined;
  data:
    | Awaited<ReturnType<Next>>['data']
    | Array<Awaited<ReturnType<Next>>['data']>;
}> => {
  if (!requestData) {
    return { next: undefined, data: {} };
  }

  try {
    const parsedError = await res.json();

    const parsedBody = JSON.parse(String(requestData?.options?.body) ?? '{}');
    if (Object.keys(parsedBody).length === 0)
      return { next: undefined, data: parsedError };

    if (!Array.isArray(parsedError)) {
      if (!isObject(parsedError)) {
        return { next: undefined, data: parsedError };
      }
    } else {
      for (const err of parsedError) {
        if (!checkParsedError(err)) {
          continue;
        }

        const result = checkParsedError(err);

        if (result) break;
      }
    }

    if (!checkParsedError(parsedError)) {
      return { next: undefined, data: parsedError };
    }

    let gotMotivation = '';

    await new Promise<void>((resolve, reject) => {
      usePromptStore.getState().prompt({ type: 'motivation' }, (motivation) => {
        if (!motivation) {
          reject('No motivation provided');
        }
        gotMotivation = motivation;
        resolve();
      });
    });

    const newBody = { data: {} };

    if (Array.isArray(parsedError)) {
      newBody.data = (parsedBody as Record<string, unknown>[]).map((inst) => {
        return {
          ...inst,
          mt_motivation: gotMotivation,
        };
      });
    } else {
      newBody.data = {
        ...parsedBody,
        mt_motivation: gotMotivation,
      };
    }

    const newRequestData: RequestData = {
      ...requestData,
      options: {
        ...requestData.options,
        body: JSON.stringify(newBody.data),
      },
    };

    switch (requestData.type) {
      case 'POST':
        return {
          next: async () =>
            await api.post({ url: '', body: {}, _requestData: newRequestData }),
          data: {},
        };
      case 'PATCH':
        return {
          next: async () =>
            await patch({ url: '', body: {}, _requestData: newRequestData }),
          data: {},
        };
      default:
        throw Error('un impletented');
    }
  } catch (err) {
    console.error('exited with error', err);
    return { next: undefined, data: {} };
  }
};
