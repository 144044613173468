import type { RequestData } from '../types';
import { handleMotivation } from './handle-motivation';
import { handleUploads } from './handle-uploads';

export const handleResponse = async (
  res: Response,
  requestData?: RequestData
) => {
  const responseData: Record<'data', { [key: string]: unknown }> = { data: {} };
  if (!res.ok) {
    if (res.status === 404) {
      throw {
        status: res.status,
        message: res.statusText,
        url: res.url,
      };
    }
    const anotherRequest = await handleMotivation(res, requestData);

    if (anotherRequest.next) {
      return await anotherRequest.next();
    }

    throw {
      status: res.status,
      message: res.statusText,
      url: res.url,
      errorData: anotherRequest.data,
    };
  }

  if (res.status === 204) {
    return responseData;
  }

  try {
    const json = await res.json();
    responseData.data = json;
  } catch (err) {
    console.error(err, 'Error parsing response');
  }

  if (requestData) {
    await handleUploads({ responseData: responseData.data, requestData });
  }

  return responseData;
};
