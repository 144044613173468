import { ObjectKeys } from '@/lib/utils';
import { useFileStore } from '@/store';
import type { RequestData } from '../types';

type File = {
  name: string;
  get: string;
  post: {
    url: string;
    fields: {
      'Content-MD5': string;
      key: string;
      'x-amz-algorithm': string;
      'x-amz-credential': string;
      'x-amz-date': string;
      'x-amz-security-token': string;
      policy: string;
      'x-amz-signature': string;
    };
  };
};

export const handleUploads = async ({
  responseData,
  requestData,
}: {
  responseData: Record<string, unknown>;
  requestData: RequestData | undefined;
}) => {
  const parsedRequestData = requestData?.options?.body
    ? JSON.parse(String(requestData.options.body) ?? '{}')
    : {};
  for (const key in responseData ?? {}) {
    const current = responseData[key];

    if (typeof current !== 'object') continue;

    if (current && 'post' in current && current?.['post']) {
      const file = current as File;
      const uploadData = parsedRequestData?.[key];

      if (!uploadData) continue;

      const data = getFormData(file, uploadData.md5_hash);

      await s3Upload(
        file['post'].url,
        data,
        uploadData.md5_hash,
        uploadData.name
      );
    }
  }
};

function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function getFormData(file: File, md5_hash: string) {
  const data = new FormData();

  const fileToUpload = useFileStore.getState().files[md5_hash];

  const blob = dataURLtoFile(fileToUpload.b64, fileToUpload.name);

  ObjectKeys(file.post.fields).forEach((key) => {
    data.append(key, file.post.fields[key]);
  });

  data.append('file', blob, fileToUpload.name);

  return data;
}

export async function s3Upload(
  postUrl: string,
  data: FormData,
  md5_hash: string,
  name: string
) {
  const file = useFileStore.getState().files[md5_hash];
  try {
    const res = await fetch(postUrl, {
      method: 'POST',
      body: data,
    });
    if (!res.ok) {
      throw new Error('Upload failed');
    } else {
      useFileStore.getState().setUploadedFile({
        [md5_hash]: {
          b64: file.b64,
          name,
          size: file.size,
        },
      });
    }
  } catch (error) {
    useFileStore.getState().setFailedFile({
      [md5_hash]: {
        data,
        name,
        postUrl,
        b64: file.b64,
        size: file.size,
        uploaded: new Date(),
      },
    });
    console.error('error', error);
  }
  useFileStore.getState().removeFile(md5_hash);
}
